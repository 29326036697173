export default {
  "row": "qzB",
  "col": "qzE",
  "header": "qzd",
  "block": "qzI",
  "col-5": "qzt",
  "col-6": "qzM",
  "col-9": "qzP",
  "col-12": "qzl",
  "hide": "qzX"
};
