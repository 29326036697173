export default {
  "header-cell": "qOF",
  "empty": "qOg",
  "header-type": "qOD",
  "header-requester": "qOV",
  "header-amount": "qOL",
  "header-approval": "qOT",
  "header-content": "qOz caption-bold",
  "active": "qOa",
  "row-compact": "qOH",
  "row-sidebar": "qOO",
  "hidden": "qOm",
  "animated": "qOp",
  "fadein-item": "qOx",
  "animated-cell": "qOw"
};
