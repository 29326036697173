export default {
  "beneficiary-details": "qmu",
  "beneficiary-account-types": "qpS",
  "divider": "qpc",
  "label": "qpq",
  "labels": "qpZ",
  "error": "qpR",
  "link": "qpQ caption",
  "sticky-panel": "qpe"
};
