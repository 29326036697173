export default {
  "wrapper": "qzW",
  "ember-power-select-dropdown": "qzu",
  "status": "qaS body-1",
  "error": "qac",
  "success": "qaq",
  "type-container": "qaZ",
  "type-close": "qaR",
  "date": "qaQ",
  "close-icon": "qae",
  "container": "qaB",
  "container-picto": "qaE",
  "avatar": "qad",
  "container-picto-status": "qaI",
  "general": "qat",
  "general-amount": "qaM",
  "unprocessed": "qaP",
  "fx-rate": "qal",
  "general-counterparty": "qaX body-2",
  "infobox": "qaC",
  "account-infobox": "qak qaC",
  "initiator-infobox": "qaJ qaC",
  "transaction-infobox": "qas qaC",
  "repeat-infobox": "qaF qaC",
  "item": "qag body-2",
  "eye-icon": "qaD",
  "repeat-transfer-action": "qaV",
  "disclaimer": "qaL",
  "start-verification-link": "qaT",
  "financing-not-compliant-disclaimer": "qaz"
};
