export default {
  "transaction": "qDm",
  "transaction-details": "qDp",
  "transaction-details-amount": "qDx body-1",
  "credit": "qDw",
  "negative-amount": "qDN",
  "transaction-details-description": "qDv body-2",
  "transaction-remove-cta": "qDo",
  "avatar": "qDn mr-16",
  "spinner": "qDi"
};
