export default {
  "wrapper": "qzz",
  "title": "qza",
  "activity-tag": "qzH",
  "select": "qzO",
  "tooltip": "qzm",
  "date-picker": "qzp",
  "internal-notes": "qzx",
  "scheduled": "qzw"
};
