export default {
  "row": "qmi body-1",
  "active": "qmA",
  "cell": "qmY",
  "empty": "qmh",
  "ellipsis": "qmf",
  "text-secondary": "qmK",
  "cell-type": "qmG",
  "cell-reason": "qmr",
  "cell-amount": "qmb",
  "row-sidebar": "qmy",
  "animated": "qmU",
  "fadein-item": "qmj",
  "animated-cell": "qmW"
};
