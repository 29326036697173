export default {
  "modal": "qgl",
  "section": "qgX",
  "header": "qgC",
  "title": "qgk",
  "icon": "qgJ",
  "content": "qgs",
  "export-template-wrapper": "qgF",
  "export-template-radio": "qgg",
  "export-template-content": "qgD",
  "export-template-label": "qgV",
  "export-template-button": "qgL",
  "export-template-list": "qgT",
  "inactive": "qgz",
  "export-template-title": "qga",
  "export-template-description": "qgH",
  "export-template-item-content": "qgO",
  "permission-disclaimer-container": "qgm",
  "export-template-footer": "qgp",
  "export-template-cta-container": "qgx",
  "upsell": "qgw",
  "upsell-section": "qgN",
  "_content": "qgv",
  "_cta": "qgo",
  "upsell-title": "qgn",
  "upsell-description": "qgi"
};
