export default {
  "transfer-form": "qHk",
  "transfer-form-title": "qHJ",
  "transfer-form-subtitle": "qHs",
  "form": "qHF",
  "form-footer": "qHg",
  "footer-btn": "qHD",
  "header": "qHV",
  "main-settings": "qHL",
  "additional-settings": "qHT",
  "form-container": "qHz"
};
