export default {
  "sidebar-content": "qzA",
  "section": "qzY",
  "header-wrapper": "qzh",
  "transfer-id": "qzf caption",
  "small-padding": "qzK",
  "title": "qzG",
  "subtitle": "qzr",
  "actions": "qzb"
};
