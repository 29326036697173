export default {
  "modal": "qFW",
  "section": "qFu",
  "header": "qgS",
  "title": "qgc",
  "separator-title": "qgq",
  "icon": "qgZ",
  "formats": "qgR",
  "columns": "qgQ",
  "column-list": "qge",
  "draggable-column": "qgB",
  "column-field": "qgE",
  "column-selector": "qgd",
  "column-with-error": "qgI",
  "handle": "qgt",
  "label-color": "qgM",
  "disclaimer": "qgP"
};
