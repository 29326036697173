export default {
  "fileupload": "qwT",
  "file": "qwz",
  "label": "qwa",
  "zone": "qwH",
  "error": "qwO",
  "hide": "qwm",
  "dropzone": "qwp",
  "hidden": "qwx",
  "upload-icon": "qww",
  "dropping": "qwN",
  "bounce2": "qwv",
  "error-message": "qwo"
};
