export default {
  "vat-lines": "qwn",
  "vat-line": "qwi",
  "vat-input": "qwA",
  "vat-rate": "qwY",
  "vat-option-button": "qwh",
  "disabled": "qwf",
  "vat-option-tooltip": "qwK",
  "remove-label": "qwG",
  "remove-button": "qwr",
  "vat-total": "qwb",
  "vat-total-amount": "qwy",
  "add-vat-line": "qwU",
  "with-vat-lines": "qwj"
};
