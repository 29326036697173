export default {
  "header-cell": "qTV",
  "header-content": "qTL caption-bold",
  "active": "qTT",
  "align-right": "qTz",
  "empty": "qTa",
  "align-checkbox": "qTH",
  "col-5": "qTO",
  "col-9": "qTm",
  "emitted-at-header-tag": "qTp"
};
