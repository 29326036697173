export default {
  "button-wrapper": "qDA",
  "btn-filter": "qDY",
  "name-wrapper": "qDh",
  "preset-name": "qDf",
  "cancel-placeholder": "qDK",
  "cancel-button": "qDG",
  "dropdown-opened": "qDr",
  "dropdown-closed": "qDb",
  "dropdown-menu": "qDy",
  "loading-item": "qDU",
  "placeholder": "qDj",
  "list-body": "qDW",
  "list-item": "qDu",
  "error-state": "qVS body-2",
  "warning": "qVc",
  "warning-icon": "qVq",
  "footer": "qVZ",
  "add-icon": "qVR",
  "empty-state": "qVQ"
};
