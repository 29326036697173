export default {
  "row": "qON",
  "cell": "qOv",
  "empty": "qOo",
  "icon": "qOn",
  "type": "qOi",
  "amount-content": "qOA",
  "cell-approve": "qOY",
  "approve-content": "qOh",
  "row-compact": "qOf"
};
