export default {
  "header-cell": "qms",
  "empty": "qmF",
  "header-content": "qmg caption-bold",
  "active": "qmD",
  "header-type": "qmV",
  "header-reason": "qmL",
  "header-status": "qmT",
  "header-amount": "qmz",
  "row-sidebar": "qma",
  "hidden": "qmH",
  "animated": "qmO",
  "fadein-item": "qmm",
  "animated-cell": "qmp"
};
