export default {
  "row": "qTx",
  "item-bulk": "qTw",
  "active": "qTN",
  "disabled": "qTv",
  "cell": "qTo body-2",
  "not-disabled": "qTn",
  "item-amount-disabled": "qTi",
  "item-amount-credit": "qTA",
  "no-padding": "qTY",
  "cell-content": "qTh",
  "centered-cell": "qTf",
  "item-counterparty-icon": "qTK mr-16",
  "avatar": "qTG",
  "item-counterparty-name": "qTr",
  "item-counterparty-name-status": "qTb",
  "disputing-chargeback": "qTy",
  "item-counterparty-name-text": "qTU",
  "item-amount": "qTj body-1",
  "item-amount-foreign": "qTW",
  "attachment-icon": "qTu",
  "item-method": "qzS",
  "bank-account-avatar": "qzc",
  "attachment-status-dot": "qzq",
  "attached": "qzZ",
  "missing": "qzR",
  "item-attachment": "qzQ",
  "fadein-item": "qze"
};
