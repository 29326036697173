export default {
  "display-block": "qpn",
  "pay-later-tooltip": "qpi",
  "disabled": "qpA",
  "stepper": "qpY",
  "installments": "qph",
  "flex-items": "qpf",
  "day-label": "qpK body-2",
  "installment-amount": "qpG body-1",
  "spacing": "qpr",
  "info-icon": "qpb",
  "detail": "qpy",
  "detail-value": "qpU",
  "extra": "qpj",
  "extra-value": "qpW",
  "badge": "qpu",
  "title": "qxS",
  "link": "qxc"
};
