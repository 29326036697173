export default {
  "transfer-header": "qTe",
  "infobox": "qTB header-section",
  "proof-section": "qTE",
  "proof-link": "qTd",
  "download-icon": "qTI",
  "cancel-button": "qTt",
  "request-refund-button": "qTM",
  "refund-requested-indicator": "qTP",
  "recall-indicator": "qTl",
  "payment-purpose": "qTX",
  "payment-purpose-label": "qTC",
  "repeat-infobox": "qTk qTB header-section",
  "additional-infobox": "qTJ qTB header-section",
  "repeat-transfer-action": "qTs",
  "financing-not-compliant-disclaimer": "qTF"
};
