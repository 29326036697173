export default {
  "custom-export-loading": "qgG",
  "title": "qgr",
  "description": "qgb",
  "section": "qgy",
  "placeholder-avatar": "qgU",
  "_with-avatar": "qgj",
  "_with-list": "qgW",
  "list-item": "qgu",
  "radio": "qDS",
  "header": "qDc",
  "header-line": "qDq"
};
