export default {
  "row": "qxv",
  "counterparty-name": "qxo",
  "counterparty-status": "qxn",
  "amount": "qxi",
  "next-date": "qxA",
  "end-date": "qxY",
  "frequency": "qxh",
  "active": "qxf",
  "cell": "qxK body-2",
  "no-padding": "qxG",
  "cell-content": "qxr",
  "counterparty": "qxb",
  "status-avatar": "qxy",
  "beneficiary-name": "qxU",
  "empty": "qxj",
  "disabled": "qxW",
  "fadein-item": "qxu"
};
