export default {
  "row": "qwS",
  "col": "qwc",
  "counterparty": "qwq",
  "header": "qwZ",
  "icon": "qwR",
  "col-12": "qwQ",
  "col-8": "qwe",
  "col-6": "qwB",
  "col-4": "qwE",
  "col-2": "qwd",
  "hide": "qwI"
};
