export default {
  "row": "qOK body-1",
  "active": "qOG",
  "cell": "qOr",
  "empty": "qOb",
  "ellipsis": "qOy",
  "text-secondary": "qOU",
  "transfer-type": "qOj",
  "transfer-type__icon": "qOW",
  "quick-actions": "qOu",
  "amount": "qmS",
  "cell-approve": "qmc",
  "cell-type": "qmq",
  "cell-requester": "qmZ",
  "account-select": "qmR",
  "row-compact": "qmQ",
  "row-sidebar": "qme",
  "cell-amount": "qmB",
  "hidden": "qmE",
  "animated": "qmd",
  "fadein-item": "qmI",
  "animated-cell": "qmt"
};
