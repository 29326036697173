export default {
  "card-header": "qLe",
  "counterparty-name": "qLB",
  "pan-section": "qLE header-section body-2",
  "infobox": "qLd header-section",
  "see-card-button": "qLI",
  "card-identifier-section": "qLt",
  "card-icon": "qLM",
  "nickname": "qLP body-1",
  "pan": "qLl body-2"
};
