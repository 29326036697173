export default {
  "wrapper": "qFN",
  "section-wrapper": "qFv",
  "header": "qFo",
  "title": "qFn body-1",
  "close-icon": "qFi",
  "section": "qFA",
  "section-title": "qFY caption-bold",
  "labels": "qFh",
  "labels-title": "qFf",
  "section-checkbox": "qFK",
  "grow": "qFG scroll",
  "label": "qFr",
  "label-spacing": "qFb",
  "buttons": "qFy"
};
