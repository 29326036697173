export default {
  "sidebar-header": "qLs",
  "sidebar-header__top": "qLF",
  "sidebar-header__body": "qLg",
  "sidebar-header__content": "qLD",
  "sidebar-header__date": "qLV",
  "infobox": "qLL",
  "infobox--link": "qLT",
  "ghost": "qLz",
  "fail": "qLa",
  "r-transaction-disclaimer": "qLH",
  "hold-disclaimer": "qLO",
  "transaction-link": "qLm body-2",
  "icon-link": "qLp"
};
