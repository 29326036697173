export default {
  "container": "qVB",
  "item": "qVE caption-bold",
  "hover": "qVd",
  "active": "qVI",
  "name-wrapper": "qVt",
  "name": "qVM body-2",
  "count": "qVP",
  "actions-placeholder": "qVl",
  "actions": "qVX",
  "show": "qVC",
  "action-button": "qVk"
};
