export default {
  "container": "qVH",
  "header": "qVO",
  "title": "qVm caption-bold",
  "search-icon": "qVp",
  "text": "qVx body-2",
  "trigger": "qVw",
  "loading-state": "qVN",
  "dropzone-placeholder-wrapper": "qVv",
  "dropzone-placeholder": "qVo",
  "file-loading": "qVn"
};
