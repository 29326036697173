export default {
  "header-cell": "qxL",
  "align-right": "qxT",
  "header-content": "qxz caption-bold",
  "active": "qxa",
  "empty": "qxH",
  "col-12": "qxO",
  "col-8": "qxm",
  "col-6": "qxp",
  "col-4": "qxx",
  "col-2": "qxw",
  "hide": "qxN"
};
